import { createRouter, createWebHistory } from 'vue-router'

const router = createRouter({
  // createWebHistory 路由模式路径不带#号(生产环境下不能直接访问项目，需要 nginx 转发)
  // createWebHashHistory 路由模式路径带#号
  history: createWebHistory(),
  routes: [
    {
      path: '/',
      redirect: '/home',
      meta: {
        title: '123书吧,免费阅读,无弹窗广告'
      },
    },
    {
      path: '/home',
      name: 'home',
      meta: {
        title: '123书吧,免费阅读,无弹窗广告'
      },
      component: () => import('@/views/Home')
    },
    {
      path: '/register',
      name: 'register',
      meta: {
        title: '注册'
      },
      component: () => import('@/views/Register')
    },
    {
      path: '/login',
      name: 'login',
      meta: {
        title: '登录'
      },
      component: () => import('@/views/Login')
    },
    {
      path: '/feadback',
      name: 'feadback',
      meta: {
        title: '反馈'
      },
      component: () => import('@/views/FeadBack')
    },
    {
      path: '/news/:id',
      name: 'news',
      meta: {
        title: '新闻'
      },
      component: () => import('@/views/News')
    },
    {
      path: '/bookclass',
      name: 'bookclass',
      meta: {
        title: '书籍分类'
      },
      component: () => import('@/views/BookClass')
    },
    {
      path: '/book_rank',
      name: 'bookRank',
      meta: {
        title: '排行榜'
      },
      component: () => import('@/views/BookRank')
    },
    {
      path: '/book/:id',
      name: 'book',
      meta: {
        title: '书籍'
      },
      component: () => import('@/views/Book')

    },
    {
      path: '/chapter_list/:bookId',
      name: 'chapterList',
      meta: {
        title: '章节列表'
      },
      component: () => import('@/views/ChapterList')

    },
    {
      path: '/book/:id/:chapterId',
      name: 'bookContent',
      meta: {
        title: '书籍内容'
      },
      component: () => import('@/views/BookContent')

    },
    {
      path: '/user/setup',
      name: 'userSetup',
      component: () => import('@/views/UserSetup')
    },
    {
      path: '/user/comment',
      name: 'userComment',
      meta: {
        title: '评论列表'
      },
      component: () => import('@/views/UserComment')

    },
    {
      path: '/author/register',
      name: 'authorRegister',
      component: () => import('@/views/author/Register')
    },
    {
      path: '/author/book_list',
      name: 'authorBookList',
      meta: {
        title: '书籍列表'
      },
      component: () => import('@/views/author/BookList')
    },
    {
      path: '/author/book_add',
      name: 'authorBookAdd',
      meta: {
        title: '添加书籍'
      },
      component: () => import('@/views/author/BookAdd')
    },
    {
      path: '/author/chapter_list',
      name: 'authorChapterList',
      meta: {
        title: '章节列表'
      },
      component: () => import('@/views/author/ChapterList')
    },
    {
      path: '/author/chapter_add',
      name: 'authorChapterAdd',
      meta: {
        title: '添加章节'
      },
      component: () => import('@/views/author/ChapterAdd')
    },
    {
      path: '/author/chapter_update',
      name: 'authorChapterUpdate',
      meta: {
        title: '更新章节'
      },
      component: () => import('@/views/author/ChapterUpdate')
    }
  ]
})

// 解决 vue 中路由跳转时，总是从新页面中间开始显示
router.afterEach((to, from, next) => {
  window.scrollTo(0, 0)
})

router.beforeEach((to, from, next) => {//beforeEach是router的钩子函数，在进入路由前执行
  if (to.meta.title) {//判断是否有标题
    document.title = to.meta.title
  }
  next()  //执行进入路由，如果不写就不会进入目标页
})


export default router